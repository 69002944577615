@layer components {
  .paragraph--code {
    @apply flex flex-col;

    /* Styles to customize all HubSpot forms */
    .hbspt-form {
      @apply prose col-span-12 mx-auto overflow-hidden rounded-xl border bg-white p-8 md:p-16 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3;

      h2 {
        @apply mb-2 mt-6 lg:mt-8;

        &:first-child {
          @apply mt-2 !text-xl md:!text-2xl lg:mt-2 lg:!text-3xl;
        }
      }

      .hs-form {
        @apply flex flex-col gap-4 lg:gap-6;
      }

      .hs-form-field {
        label {
          @apply inline-flex gap-1 font-display text-sm font-semibold;

          .hs-form-required {
            @apply text-sm text-negative-600;
          }
        }

        .hs-input {
          @apply block h-[58px] w-full rounded border-0 px-4 py-2.5 leading-none text-gray-900 outline-0 ring-1 ring-inset ring-border transition-all placeholder:font-normal placeholder:text-black-300 hover:ring-gray-700 focus:outline-0 focus:ring-inset focus:ring-orange focus:hover:ring-orange sm:text-sm;

          &.error {
            @apply ring-negative;
          }

          &[disabled] {
            @apply bg-background hover:ring-border;
          }
        }

        .hs-error-msgs {
          @apply my-0 list-none pl-0;

          li {
            @apply mb-0 pl-0;

            label {
              @apply mb-0 mt-1 text-sm font-normal text-negative-600;
            }
          }
        }
      }

      .hs-fieldtype-textarea {
        textarea {
          @apply !h-[100px] py-4;
        }
      }

      .hs-submit {
        @aply border-t mt-6 flex items-center justify-start px-8 md:px-16 pt-8 pb-10;

        .actions {
          @apply inline-flex flex-col gap-4 md:flex-row;
        }

        .hs-button[type="submit"] {
          @apply btn btn-green btn-lg mr-auto inline-flex h-auto cursor-pointer !border-0 font-medium hover:ring-green focus:ring-green;
        }
      }

      .hs-fieldtype-booleancheckbox {
        ul {
          @apply my-0 list-none pl-0;

          .hs-form-booleancheckbox {
            label {
              @apply relative mt-6 flex items-start gap-2;

              input {
                @apply grid h-6 w-6 place-items-center rounded-sm !border !border-border bg-white p-0.5 text-black transition-colors hover:border-green-300 focus:border-green focus:text-green;

                + span {
                  @apply text-sm font-normal lg:translate-y-[.1rem] xl:inline-flex xl:gap-2;
                }
              }
            }
          }
        }
      }

      fieldset {
        /* By some reason when in HabSpot form we have group of inputs in multiple columns it has max-width = 500px. We don't have to have this limitation.*/
        /* Note: In cases like this fieldset also has one of next clases: form-columns-0 or form-columns-1 or form-columns-2 or ... */
        max-width: none;
      }

      /* In HubSpot forms it is a checkbox-list where user can select multiple checkboxes for the same label */
      .hs-fieldtype-checkbox {
        ul {
          @apply my-0 list-none pl-0;

          .hs-form-checkbox {
            label {
              @apply relative mt-6 flex items-start gap-2;

              input {
                @apply grid h-6 w-6 place-items-center rounded-sm !border !border-border bg-white p-0.5 text-black transition-colors hover:border-green-300 focus:border-green focus:text-green;

                + span {
                  @apply text-sm font-normal lg:translate-y-[.1rem] xl:inline-flex xl:gap-2;
                }
              }
            }
          }
        }
        .hs-form-checkbox {
        }
      }

      .hs-fieldtype-radio {
        ul {
          @apply my-0 flex list-none flex-col gap-2 pl-0 lg:gap-3;

          .hs-form-radio {
            @apply my-0;

            label {
              @apply relative my-0 flex items-start gap-2;

              input {
                @apply grid h-6 w-6 place-items-center rounded-full !border !border-border bg-white p-0.5 text-black transition-colors hover:border-green-300 focus:border-green focus:text-green;

                + span {
                  @apply inline-flex translate-y-[.1rem] gap-2 text-sm font-normal;
                }
              }
            }
          }
        }
      }

      .hs_error_rollup {
        .hs-error-msgs {
          @apply my-0 list-none rounded-lg bg-negative-100 !px-4 !py-2;

          li {
            @apply my-0 pl-0;

            label {
              @apply my-0 text-sm font-normal text-negative-600;
            }
          }
        }
      }

      [type="radio"] {
        &:checked {
          @apply !bg-green;

          background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e") !important;
          background-size: 1rem;
        }

        &:focus {
          @apply !border-green !ring-1 !ring-green/40;
        }
      }

      [type="checkbox"] {
        &:checked {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjIuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIzM3B4IiBoZWlnaHQ9IjMzcHgiIHZpZXdCb3g9IjAgMCAzMyAzMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzMgMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDA4NzY2O30KPC9zdHlsZT4KPGcgaWQ9IkNoZWNrIj4KCTxnIGlkPSJWZWN0b3IiPgoJCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMi41LDI2LjJjLTAuMywwLTAuNS0wLjEtMC43LTAuM2wtNy03Yy0wLjQtMC40LTAuNC0xLDAtMS40czEtMC40LDEuNCwwbDYuMyw2LjNMMjcuOCw4LjUKCQkJYzAuNC0wLjQsMS0wLjQsMS40LDBzMC40LDEsMCwxLjRsLTE2LDE2QzEzLDI2LjIsMTIuNywyNi4yLDEyLjUsMjYuMnoiLz4KCTwvZz4KPC9nPgo8L3N2Zz4K") !important;
          background-size: 1rem;
        }

        &:focus {
          @apply !border-green !ring-0;
        }
      }
    }
  }

  label {
    @apply mb-2 inline-block cursor-pointer text-base font-normal text-black;

    a {
      @apply text-green underline;
    }

    &.text-white A {
      color: #fff;
    }
  }

  .label-required {
    @apply after:pl-0.5 after:text-negative after:content-['*'];
  }

  .form-input,
  input {
    @apply block h-[58px] w-full rounded border-0 px-4 py-2.5 leading-none text-gray-900 outline-0 ring-1 ring-inset ring-border transition-all placeholder:font-normal placeholder:text-black-300 hover:ring-gray-700 focus:outline-0 focus:ring-inset focus:ring-orange focus:hover:ring-orange sm:text-sm;

    &[data-error="true"] {
      @apply ring-negative;
    }

    &[disabled] {
      @apply bg-background hover:ring-border;
    }
  }

  .form-input-lg {
    @apply text-sm lg:text-lg;
  }

  .form-input-xl {
    @apply h-[72px] rounded-lg text-sm lg:text-lg;
  }

  .form-error {
    @apply mt-1 text-sm text-negative-600;

    .text-white & {
      color: #fff;
    }
  }

  [type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='green' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e") !important;
  }

  .radio-slider {
    @apply relative;

    &__item {
      @apply relative z-1 inline-flex items-center justify-center p-2 transition-all;

      label {
        &::after {
          @apply absolute inset-0 -z-10 block h-full w-full rounded border border-dashed bg-white transition content-[""];
        }

        &:hover {
          &::after {
            @apply border-green;
          }
        }
      }

      input {
        @apply hidden;

        &:checked {
          & + div label {
            &::after {
              @apply border-green bg-green;
            }
          }
        }
      }
    }
  }
}
