@layer components {
  blockquote {
    @apply relative px-6 py-8 font-display !text-xl font-bold !leading-snug !text-orange lg:px-16 lg:py-12 lg:!text-4xl;

    &::before,
    &::after {
      content: open-quote;
      background: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='79.8px' height='50.5px' viewBox='0 0 79.8 50.5'%3E%3Cpath fill='%23ffe533' d='M18.8,0c9,0,16.5,6.3,18.3,14.7c3.4,15.2-7.1,28.6-19.8,35.8l1.4-12.9C8.4,37.6,0,29.2,0,18.8 C0,8.4,8.4,0,18.8,0L18.8,0z M60.9,0c9,0,16.5,6.3,18.3,14.7c3.4,15.2-7.1,28.6-19.8,35.8l1.4-12.9C50.5,37.6,42,29.2,42,18.8 C42,8.4,50.5,0,60.9,0L60.9,0z'/%3E%3C/svg%3E")
        50% 50% no-repeat;
      @apply absolute h-8 w-10 bg-contain text-transparent lg:h-12 lg:w-16;
    }

    &::before {
      @apply left-0 top-0 -translate-x-full -translate-y-full rotate-180 scale-150 opacity-0;
    }

    &::after {
      @apply bottom-0 right-0 translate-x-full translate-y-full -rotate-180 scale-150 opacity-0;
    }

    &.in-viewport {
      &::before {
        @apply animate-blockquoteBefore;
        animation-fill-mode: forwards !important;
      }
      &::after {
        @apply animate-blockquoteAfter;
        animation-fill-mode: forwards !important;
      }
    }

    p {
      &::before,
      &::after {
        @apply !content-none;
      }
    }

    & + .blockquote__author {
      @apply !mb-0 -mt-6 ml-6 text-sm text-orange lg:-mt-8 lg:ml-16 lg:text-lg;
    }
  }
}
