@layer components {
  .number-pagination {
    @apply mx-auto;

    &:has(.ant-pagination-prev.ant-pagination-disabled):has(
        .ant-pagination-next.ant-pagination-disabled
      ) {
      @apply !hidden;
    }

    .ant-pagination-item,
    .ant-pagination-item-link {
      @apply !rounded-full;
    }

    a {
      @apply !text-black;
    }

    .ant-pagination-item-active {
      @apply !rounded-full border-yellow bg-yellow;

      a {
        @apply font-bold !text-black;
      }
    }
  }
}
