@layer utlities {
  .limit-5-children {
    > :nth-child(n + 6) {
      @apply !hidden;
    }

    /* Display toggle button */
    > :last-child {
      @apply !block;
    }
  }

  .stretched-link::after {
    content: "";
    @apply pointer-events-auto absolute inset-0 z-1 bg-transparent;
  }

  .text-balance {
    text-wrap: balance;
  }

  .offset-hero {
    @apply xl:!pt-[330px];
  }

  .offset-header {
    @apply xl:!pt-[170px] 2xl:!pt-[192px];
  }

  .bg-left-of-container-white {
    @apply relative;

    &:before {
      @apply absolute inset-y-0 right-full -z-1 -mr-6 w-screen bg-white content-[''];
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
