@layer components {
  .link {
    @apply relative font-medium leading-relaxed;

    &:hover {
      &::before {
        @apply w-3;
      }
    }

    &::before {
      @apply absolute bottom-0 left-0 h-0.5 w-full bg-orange transition-all content-[''];
    }
  }
}
