@layer components {
  .ant-select {
    &-selector {
      @apply form-input min-h-[58px] rounded border !border-border text-sm ring-0 !important;
    }

    &-focused {
      @apply !ring-0;

      .ant-select-selector {
        @apply !border-orange;
      }
    }

    &-single {
      @apply h-auto !important;
    }

    &-selection-search-input {
      @apply ring-0 focus:outline-0 focus:ring-0 !important;
    }

    &-selection-search {
      margin-inline-start: 0 !important;
    }

    &-selection-placeholder {
      @apply px-1 text-black-300 !important;
    }

    &-item-option-selected {
      @apply bg-green text-white !important;
    }

    &-item-option-state {
      @apply text-white !important;
    }

    &-clear {
      @apply mr-6 !important;
    }
  }
}
