@layer components {
  .ant-picker {
    input {
      @apply ring-0;
    }

    &-focused {
      @apply !border !border-orange !ring-0;
    }
  }
}
